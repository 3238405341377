<template>
    <div class="container">
      <header-with-line header="Spüre den Unterschied" class="mb-4" />
      <div class="col-lg-9 mx-auto text-center pb-3">
          <h3 class="text-background">… wähle Therapie, die zu dir passt:</h3>
      </div>
      <div class="row d-flex justify-content-center">
        <img class="img-fluid mb-3" src="../../assets/img/decorations/all_patients_are_welcome.svg" alt="Fleckdekoration">
      </div>
      <div class="text-primary py-2 fs-4 text-justify">
          <p>
            … ob <span class="text-bold">gesetzlich versichert, privat oder Selbstzahler</span>.
            Die Kosten für gesetzlich Versicherte werden <span class="text-bold">vollständig oder größtenteils (90%) von den Krankenkassen übernommen</span>,
            entsprechend der geltenden Heilmittelrichtlinien.
          </p>
          <p>
            Auf dieser Seite findest du die Preisinformationen speziell für Privatpatienten und Selbstzahler,
            die ihre Behandlungen selbst finanzieren oder privat abrechnen. Für alle anderen Patient*innen gelten
            die vertraglich festgelegten Kassenleistungen.
          </p>
      </div>
    </div>
</template>

<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';

export default {
    name: 'FeelDifference',
    components: {
        HeaderWithLine
    },
    data: () => ({
    }),
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};

</script>

<style>
.text-bold {
    font-weight: bold;
}

.img-container {
    position: relative;
}
</style>

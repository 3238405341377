<template>
  <layout-default :sections="sections" :activeSection="activeSection">
    <section class="services bg-light py-0" id="feel-difference" data-description="Pakete">
      <feel-difference />
      <our-offer />
    </section>
    <section class="services bg-light py-4">
      <self-payer />
    </section>
    <section class="services bg-white py-1" id="price-table" data-description="Preisliste">
      <price-table />
    </section>
    <section class="services bg-light pt-0 pb-4" id="answers-questions" data-description="FAQ">
      <answers-questions />
    </section>
    <section class="services bg-white p-0" id="price-support" data-description="Erstattungshilfe">
      <price-support />
    </section>
  </layout-default>
</template>

<script>
import { collectSections, observeSections } from '../utils/sectionCollector';
import LayoutDefault from '../layouts/LayoutDefault.vue';
import FeelDifference from '../components/Price/FeelDifference.vue';
import OurOffer from '../components/Price/OurOffer.vue';
import PriceTable from '../components/Price/PriceTable.vue';
import PriceSupport from '../components/Price/PriceSupport.vue';
import AnswersQuestions from '../components/Price/AnswersQuestions.vue';
import SelfPayer from '../components/Price/SelfPayer.vue';

export default {
    name: 'Prices',
    components: {
        LayoutDefault,
        FeelDifference,
        OurOffer,
        PriceTable,
        PriceSupport,
        AnswersQuestions,
        SelfPayer
    },
    data() {
        return {
            sections: [],
            activeSection: ''
        };
    },
    methods: {
        updateActiveSection(id) {
            this.activeSection = id;
        }
    },
    mounted() {
        this.sections = collectSections();
        observeSections(this.sections, this.updateActiveSection);
    }
};
</script>

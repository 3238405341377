<template>
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-12 col-lg-8">
        <img class="img-fluid mb-3" src="../../assets/img/decorations/information_for_self-payers.svg" alt="Fleckdekoration">
      </div>
    </div>
    <div class="text-primary py-2 fs-4 text-justify">
        <p>
          Für Selbstzahler*innen bieten wir ausschließlich <span class="text-bold">physiotherapeutische
          Heilpraktiker-Leistungen</span> an. Ergotherapie und Logopädie stehen für Selbstzahler
          leider nicht zur Verfügung. Falls du an diesen Leistungen interessiert bist,
          empfehlen wir dir, eine Privatverordnung von deinem Arzt oder deiner Ärztin
          zu erhalten – so können wir dir eine umfassende Behandlung anbieten.
        </p>
        <p>
          <span class="text-bold">Unsere Tarife für Selbstzahler*innen in der Physiotherapie</span>
          Selbstzahler-Termine können bei uns flexibel in Einheiten von  <span class="text-bold">30</span>
          oder <span class="text-bold">60 Minuten</span>
          gebucht werden. Die Preise für diese Einzelleistungen werden beim ersten Termin
          fällig und müssen zu diesem Zeitpunkt vollständig beglichen werden.
        </p>
        <p>
          Diese flexiblen Optionen machen die Buchung ganz einfach und ermöglichen dir,
          genau die Therapiezeit zu wählen, die zu deinen Bedürfnissen passt.
        </p>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="text-center col-md-12 col-lg-4">
        <AppointmentReservation />
      </div>
    </div>
  </div>
</template>

<script>
import AppointmentReservation from '../Ui/AppointmentReservation.vue';

export default {
    name: 'SelfPayer',
    components: {
        AppointmentReservation
    },
    data: () => ({
    }),
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};

</script>

<style>
.text-bold {
  font-weight: bold;
}

.img-container {
  position: relative;
}
</style>

<template>
  <div>
    <div class="container">
      <header-with-line header="Unterstützung bei den Kosten" class="mb-4"/>
      <div class="col-lg-9 mx-auto text-center">
        <div class="text-background h3">… wir helfen Privatpatienten zu ihrem Recht.</div>
      </div>
    </div>
    <div class="container p-2">
      <div class="row m-auto d-flex justify-content-center align-items-center">
        <div class="col-lg-3 col-md-12 text-center">
            <img src="../../assets/img/helenaarchive.png" alt="Helena" class="img-fluid img-border">
        </div>
        <div class="col-lg-7 col-md-12 fs-5 my-2 text-justify">
            <p>
              Als Privatpatient kannst du bei uns zwischen den Paketen <span class="text-bold">Friends</span> und <span class="text-bold">Komfort</span> wählen.
              Unabhängig davon, welches Paket du wählst, unterstützen wir dich bei der Klärung
              von Kostenerstattungen durch deine private Krankenversicherung.
              Sollten Probleme oder Kürzungen bei der Erstattung auftreten,
              bieten wir dir in Zusammenarbeit mit Privatpreise.de eine kostenfreie
              Prüfung deiner PKV-Verträge an.
            </p>
            <p>
              Durch die Eingabe eines speziellen Codes, den du von uns erhältst,
              kannst du den Service auf Privatpreise.de kostenfrei nutzen.
              Hierbei prüfen Spezialisten und Anwälte deine Erstattungsansprüche und unterstützen dich,
              falls die Versicherung nicht den vollen Betrag erstattet.
              In vielen Fällen sind Patienten im Recht, und die komplette Erstattung kann eingefordert werden.
              Mehr Informationen dazu findest du unter <u><a href="https://www.privatpreise.de" target="_blank">Privatpreise.de</a></u> – nutze diesen Service und setze dich für dein Recht ein!
            </p>
        </div>
          <div class="col-lg-12 text-center fs-5 mt-4">
            <p>
              Mehr Informationen dazu findest du unter <u><a href="https://www.privatpreise.de" target="_blank">Privatpreise.de</a></u>
              – nutze diesen Service und setze
              dich für dein Recht ein!
            </p>
          </div>
      </div>
      <div class="row d-flex justify-content-center">
          <div class="text-center col-md-12 col-lg-4">
            <a href="/Flyer_Privat_Patienten.pdf" target="_blank" style="text-decoration: none;">
              <div class="text-white text-center bg-primary fs-4 reserve-box">
                Info Flyer PKV
              </div>
            </a>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';

export default {
    name: 'PriceSupport',
    components: {
        HeaderWithLine
    },
    data() {
        return {
        };
    },
    methods: {
    }
};
</script>
<style>
.img-border {
  border-radius: 25px;
  border: 5px solid #b0c34a;
}

.reserve-box {
  border-radius: 30px;
  border: 5px solid #b0c34a;
  padding: 30px;
  margin: 20px 0;
  cursor: pointer;
}

.reserve-box:hover {
  border: 5px solid #07425e;
  background-color: #b0c34a !important;
  transition: 0.3s;
}
</style>

<template>
  <div>
    <div class="container">
      <header-with-line header="Unsere Preisliste" class="mb-4" />
      <div class="col-lg-9 mx-auto text-center mb-3">
        <h3 class="text-background">… für Privat & Selbstzahler</h3>
      </div>
    </div>
    <div class="container d-flex justify-content-center p-0">
      <div class="col-lg-9 p-0">
        <table class="table text-center">
        <thead class="bg-primary text-white">
          <tr class="">
            <th class="h3">Tarif</th>
            <th class="h3">Friends<br><span class="small">(Satz 1,4)</span></th>
            <th class="h3">Komfort<br><span class="small">(Satz 1,8)</span></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td v-html="item.tarif" class="bg-tertiary"></td>
            <td v-html="item.friends" class="text-bold"></td>
            <td v-html="item.komfort" class="text-bold"></td>
          </tr>
        </tbody>
      </table>
      <div class="text-background"><p>*(Komfort = 30 Min., Rundum = 60 Min., Vertieft = 90 Min. Therapieeinheit)</p></div>
      </div>
    </div>
    <div class="container text-center d-flex justify-content-center p-0">
      <div class="col-lg-9 p-0">
        <div class="h2">👆Hinweis:</div>
        <div class="fs-5">
          Die aufgeführten Preise gelten pro Termin und variieren je nach ausgewähltem Therapiepaket.
          Bitte beachte, dass sich die Kosten individuell nach deinen Therapieanforderungen richten.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';

export default {
    name: 'PricTable',
    components: {
        HeaderWithLine
    },
    data() {
        return {
            items: [
                {
                    tarif: `Logo. / Physio. / Ergo. <br>
                            <span style="font-weight: bold;">30 Min.</span>`,
                    friends: '68,50 €',
                    komfort: '88,07 €'
                },
                {
                    tarif: `Logo. / Physio. / Ergo. <br>
                            <span style="font-weight: bold;">60 Min.</span>`,
                    friends: '137,00 €',
                    komfort: '176,14 €'
                },
                {
                    tarif: `Logo. / Physio. / Ergo. <br>
                            <span style="font-weight: bold;">90 Min.</span>`,
                    friends: '205,50 €',
                    komfort: '264,20 €'
                },
                {
                    tarif: 'Ergänzendes Heilmittel<br>( <span style="font-weight: bold;">je 15 Min.</span> )',
                    friends: '15,71 €',
                    komfort: '20,20 €'
                },
                {
                    tarif: 'Hausbesuch Wegepauschale',
                    friends: '26,78 €',
                    komfort: '34,43 €'
                },
                {
                    tarif: 'Vor- und Nachbereitungszeit',
                    friends: '34,25 €',
                    komfort: '44,03 €'
                },
                {
                    tarif: 'Ausführlicher Therapiebericht',
                    friends: '88,90 €',
                    komfort: '114,30 €'
                }
            ]
        };
    },
    methods: {
        splitanswer(answer) {
            return answer.split(';');
        }
    }
};
</script>

<style scoped>
.table th,
.table td {
  text-align: center;
  word-wrap: break-word;
  white-space: normal;
  vertical-align: middle;
}
.second_header {
  background-color: #7f9ca9;
}

.text-bold {
  font-weight: bold;
}
</style>

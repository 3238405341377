<template>
  <div class="container">
    <header-with-line header="Häufig gestellte Fragen zu Privatpreisen" class="mb-4" />
    <div class="accordion px-1" role="tablist">
      <b-card no-body v-for="(question, index) in questions" :key="index">
        <b-card-header header-tag="header" role="tab">
          <b-button block v-b-toggle="'accordion-' + index" class="d-flex align-items-center justify-content-left">
            <i :class="accordionOpen[index] ? 'far fa-minus-circle mr-3 text-size' : 'far fa-plus-circle mr-3 text-size'"></i>
            <div class="fs-4 text-left">{{ question.title }}</div>
          </b-button>
        </b-card-header>
        <b-collapse
          :id="'accordion-' + index"
          accordion="my-accordion"
          role="tabpanel"
          v-model="accordionOpen[index]"
        >
          <b-card-body>
            <b-card-text>
              <h5 v-html="question.answer"></h5>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
    BCard, BCardBody, BCardHeader, BCardText, BButton, BCollapse
} from 'bootstrap-vue';
import HeaderWithLine from '../Ui/HeaderWithLine.vue';

export default {
    name: 'AnswersQuestions',
    components: {
        HeaderWithLine,
        BCard,
        BCardBody,
        BCardHeader,
        BCardText,
        BButton,
        BCollapse
    },
    data() {
        return {
            questions: [
                {
                    title: '1. Werden die Behandlungskosten von meiner privaten Krankenversicherung (PKV) vollständig übernommen?',
                    answer: 'Die Kostenübernahme hängt von deinem PKV-Tarif ab. Manche Versicherungen übernehmen nicht den vollen Betrag der Behandlung. Wir unterstützen dich mit einem Kostenvoranschlag und bieten zusätzlich unseren besonderen Service: Mit der „Roten Karte“ kannst du deinen Vertrag kostenlos auf unserer Partnerseite <a href="https://www.privatpreise.de" target="_blank" rel="noopener noreferrer">www.privatpreise.de</a> prüfen lassen. Diese Experten helfen dir, eine möglichst hohe Kostenbeteiligung deiner Versicherung zu erreichen.'
                },
                {
                    title: '2. Was passiert, wenn meine Versicherung nicht die vollen Kosten übernimmt?',
                    answer: 'Falls deine Versicherung nicht den gesamten Betrag übernimmt, bist du verpflichtet, den Restbetrag selbst zu zahlen. Das wird in der Honorarvereinbarung festgehalten, die wir vor Therapiebeginn abschließen.'
                },
                {
                    title: '3. Wie werden die Preise für meine Behandlung festgelegt?',
                    answer: 'Unsere Preise basieren auf der Gebührenübersicht für Therapeuten (GebüTh) und beziehen sich auf die Heilmittel Physiotherapie, Ergotherapie und Logopädie. Die genauen Preise werden im Vorfeld besprochen und in der Honorarvereinbarung festgehalten.'
                },
                {
                    title: '4. Was mache ich, wenn meine PKV die Erstattung verweigert?',
                    answer: 'Sollte deine Versicherung die Erstattung kürzen oder verweigern, unterstützen wir dich. Wir helfen dir bei der Kommunikation mit der Versicherung und stellen dir, falls nötig, Argumente und Gerichtsurteile zur Verfügung.'
                },
                {
                    title: '5. Gibt es eine amtliche Gebührenordnung für Heilmittel?',
                    answer: 'Nein, für Heilmittel gibt es keine amtliche Gebührenordnung wie bei ärztlichen Leistungen. Die Preise werden zwischen uns und dir frei vereinbart.'
                },
                {
                    title: '6. Kann ich eine Rechnung für die Beihilfe einreichen?',
                    answer: 'Ja, beihilfeberechtigte Patient*innen können unsere Rechnungen einreichen. Beachte jedoch, dass die Beihilfe oft nur einen Teil der Kosten übernimmt, und der Restbetrag von dir selbst zu tragen ist.'
                }
            ],
            accordionOpen: []
        };
    },
    methods: {
    },
    mounted() {
        this.accordionOpen = this.questions.map(() => false);
    }
};
</script>

<style>
.text-size {
    font-size: 2em;
    color: var(--bs-text);
}
</style>
